@import '../../scss-variables';

.formSelectContainer {
  box-sizing: border-box;
  position: relative;
  width: 211px;
}
.formContainer {
  width: 211px;
  justify-content: center;
}
.formSelect {
  cursor: pointer;
  box-sizing: border-box;
  width: 100%;
  height: 44px;
  position: relative;
  appearance: none;
  background: $simple_white;
  font-size: 16px;
  color: $input_text_color;
  outline: none;
  display: flex;
  justify-content: space-between;
  z-index: 11;
  border: 1px solid $select_border_color;
  border-radius: 10px;
  padding: 12px 16px;
  transition: border .3s ease;
}
.formText {
  display: block;
  color: #111827;
}

.formOptions {
  box-sizing: border-box;
  background: $simple_white;
  color: $input_text_color;
  border-radius: 0 0 10px 10px;
  width: 100%;
  overflow-y: auto;
  transition: all 0.4s;
  scrollbar-color: #439FFE #E5E6E7!important; // Firefox support
  scrollbar-width: thin!important; // Firefox support
}

.formOption {
  direction:ltr;
  font-size: 16px;
  padding: 5px 10px;
  outline: none;
  margin: 5px 0;
  color: #111827;
  cursor: pointer;
}
.formCheckbox {
  margin-right: 5px;
}
.formArrow {
  position: absolute;
  background-image: url('../../assets/images/chevron-down.svg');
  background-repeat: no-repeat;
  background-position: center;
  width: 14px;
  height: 18px;
  top: 13px;
  right: 13px;
  z-index: 13;
  transition: transform .5s ease;
}

.container {
  box-sizing: border-box;
  background-color: $simple_white;
  width: 211px;
  overflow: hidden;
  border: 1px solid $select_border_color;
  border-radius: 16px;
  padding-left: 10px;

  position: absolute;
  top: 55px;
  left: 0;
  z-index: 100;
  //max-height: 196px;
  transition: max-height 0.4s ease;
}

.optionsContainer {
  direction:rtl;
  padding: 10px 0;
}

/**
 Handling Scroll bar styles
 */
.formOptions::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-track {
  background-color: #E5E6E7;
  border-radius: 10px;
  margin: 10px
}
.formOptions::-webkit-scrollbar-thumb {
  background: #439FFE;
  border-radius: 5px;
  max-height: 10px;
}
