@import '../../scss-variables';

.container {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.button {
  display: flex;
  align-items: center;
  white-space: nowrap;
  border: none;
  justify-self: right;
  padding: 12px 16px;
  width: 309px;
  height: 44px;
  outline: none;
  transition: background-color .3s ease;

  font-family: $main_font;
  font-size: 16px;
  font-weight: 500;
  color: $simple_white;

  background: #1E9A57;
  border-radius: 10px;
  cursor: pointer;
}

.button:hover {
  background-color: $button_hover_color;
}

.button:disabled {
  background-color: #A8E6C5;
  cursor: not-allowed;
}

.icon {
  margin-right: 10px;
  width: 16px;
  height: 18px;
}
