.button {
  position: absolute;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  transition: all .3s ease;
  background: url('../../assets/images/times.svg') no-repeat center;

  &:hover {
    background: #FEE2E2 url('../../assets/images/times_alert.svg') no-repeat center;
  }
}
