$main_font: 'Euclid Circular B', sans-serif;
$base_color: #E5E5E5;
$title_color: #43A0FF;
$simple_white: #FFFFFF;
$exit_font_color: #F87171;
$error_color: #991B1B;
$error_border_color: #DC2626;
$disabled_login_color: #B4D9FF;
$input_text_color: #848484;
$pagination_border_color: #D1D0D0;
$select_border_color: #CACACA;
$main_bgc: #F5F5F5;
$disabled_button_color: #B7B7B7;
$button_hover_color: #137540;
$placeholder_color: #979A9E;
$max_screen: 1921px;
$half_table_width: 960px;
