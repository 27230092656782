.button {
  position: absolute;
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  transition: all .3s ease;
}
