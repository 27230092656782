@import '../../../scss-variables';

.container {
  max-height: 230px;
  min-height: 230px;
  background-color: $simple_white;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 0;
  transition: max-height 1s ease, min-height 1s ease;
}

.confirmation {
  display: flex;
  justify-content: space-between;
  background-color: $main_bgc;
  padding: 15px 100px;
}

.header {
  font-size: 28px;
  line-height: 36px;
  color: #0D0D0E;
}

.button {
  margin-top: 6px;
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  justify-self: right;
  padding: 12px 16px;
  width: 309px;
  height: 44px;
  transition: background-color .3s ease;

  font-family: $main_font;
  font-size: 16px;
  font-weight: 500;
  color: $simple_white;

  background: #1E9A57;
  border-radius: 10px;
  cursor: pointer;
}

.button:hover {
  background-color: $button_hover_color;
}

.button:disabled {
  background: $disabled_button_color;
  cursor: not-allowed;
}

.icon {
  margin-right: 10px;
  width: 16px;
  height: 18px;
}

.form {
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 0 100px;
  min-width: 800px;
  background-color: #fff;
  position: relative;
}

.topics {
  flex: 1;
  padding: 30px 0;
  margin-right: 5px;
}

.headings {
  font-size: 20px;
  line-height: 25px;
  color: #0D0D0E;
}

.text {
  font-size: 16px;
  line-height: 16px;
  margin-top: 16px;
  color: #000000;
}

.divider {
  flex: 1;
  max-width: 2px;
  background-color: $main_bgc;
  margin-right: 30px;
}

.radio {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-top: 35px;
  margin-left: 10px;
  cursor: pointer;
  user-select: none;

  font-size: 16px;
  line-height: 16px;
  color: #000000;
}

.radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: $simple_white;
  border: 1px solid $title_color;
  border-radius: 50%;
}

.checkmark:after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  background-color: $title_color;
  border-radius: 50%;
  display: none;
}

.radio input:checked ~ .checkmark:after {
  display: block;
}

.radio .checkmark:after {
  top: 6px;
  left: 6px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: $title_color;
}

