@import '../../scss-variables';

.form {
  box-sizing: border-box;
  padding: 40px 40px 35px;
  max-width: 418px;
  min-height: 342px;
  background: $simple_white;
  box-shadow: 0 5px 10px rgba(160, 163, 189, 0.1);
  margin: 0 auto;

  &_container {
    height: calc(100vh - 70px);
    min-height: 500px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  &_content {
    padding-bottom: 50px;
    height: fit-content;
    box-sizing: border-box;
  }

  &_title {
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    color: $title_color;
    letter-spacing: 1px;

    @media screen and (max-height: 650px) {
      display: none;
    }
  }

  &_text {
    color: #505258;
    font-size: 14px;
    line-height: 34px;
    text-align: center;
    margin-bottom: 20px;
  }

  &_fields {
    position: relative;
    margin-bottom: 25px;
  }

  &_input {
    display: block;
    width: 100%;
    height: 44px;
    border: 1px solid #E5E6E7;
    box-sizing: border-box;
    border-radius: 4px;
    outline: none;
    padding: 5px 16px;

    font-family: $main_font;
    color: #323436;
    font-size: 14px;
    font-weight: 500;
  }

  &_input:focus {
    border: 1px solid #439FFE;
    box-shadow: 0 0 10px rgba(26, 27, 31, 0.05);
  }

  &_input-error {
    border-color: $error_border_color;
  }

  &_label {
    display: block;
    font-size: 14px;
    line-height: 18px;
    margin: 0 0 6px;
    color: #2A2C34;
  }

  &_image {
    position: absolute;
    top: 29px;
    right: 15px;
    cursor: pointer;
  }

  &_button {
    height: 44px;
    width: 100%;
    font-family: $main_font;
    font-weight: 500;
    font-size: 16px;
    line-height: 44px;
    color: #fff;
    background-color: #439FFE;
    border-radius: 4px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  &_button:disabled {
    background-color: $disabled_login_color;
    cursor: not-allowed;
  }

  &_button:active {
    background-color: $disabled_login_color;
  }

  &_error {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: $error_color;
    line-height: 24px;
    margin-bottom: 16px;
    padding-right: 20px;
    max-height: 0;
    transition: max-height 0.3s ease;
    overflow: hidden;

    &_image {
      cursor: pointer;
      width: 17px;
      height: 17px;
      margin-right: 5px;
    }
  }
}
