.container {
  padding: 0 100px;
  font-size: 16px;
  color: #95989C;
  height: 30px;
  display: flex;
  align-items: center;
}

.iconHome {
  width: 18px;
  height: 16px;
}

.rightIcon {
  width: 6px;
  height: 16px;
  margin: 0 8px;
}

.link {
  height: 16px;
}
